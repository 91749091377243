import React from 'react'

export default function CustomerSatisfaction() {
    return (
        <div className="customer-satisfaction">
            <h1>Customer Survey</h1>
            <p>ksdksdakdsaksd</p>
        </div>
    )
}
